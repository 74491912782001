import * as React from "react";
import {useState} from "react";
import {Button, Alert, Input} from 'reactstrap';

export default function HelloPage() {
    return <div className="m-3">
        <h1>Hello!</h1>
        <SayHelloForm />
    </div>;
}


function SayHelloForm() {
    const [name, setName] = useState("world");

    const doStuff = (evt)=> {
        evt.preventDefault();
        alert(`Hello, ${name}!`);
    };

    return <div>
        <form onSubmit={doStuff} className="mb-3">

            <Input type="text" value={name} onChange={evt => setName(evt.target.value)} className="mb-3" />

            <Button type="submit" color="primary">
                Say hello!
            </Button>
        </form>

        <Alert color="primary">
            Hello, {name || 'world'}!
        </Alert>

    </div>;
}
